import React, {createContext, useReducer} from 'react';

const initialState = {
    isSidebarOpened: false,
    premiumLocationFilter : 'all',
    premiumTimePeriodFilter : 12,
    statementModal : {
        isOpen : false,
        uln : '',
        id: ''
    },
    isUserOpen: false,
    roles: [],
    ulns: [],
    contact: {},
    modalMessage: "",
    refresh: null,
    isDeleteModalOpened: false,
    modalDeleteMessage: "",
    deleteContact: {}
};
const store = createContext(initialState);
const {Provider} = store;

function reducer(state, action) {
    switch (action.type) {
        case 'toggle_sidebar':
            return {
                ...state,
                isSidebarOpened : !state.isSidebarOpened
            }
        case 'premium_location_filter_change':
            return {
                ...state,
                premiumLocationFilter : action.payload
            }
        case 'premium_time_period_filter_change':
            return {
                ...state,
                premiumTimePeriodFilter : action.payload
            }
        case 'toggle_statement_modal':
            return {
                ...state,
                statementModal : {
                    isOpen : action.payload.isOpen,
                    uln : action.payload.uln,
                    id: action.payload.id
                }
            }
        case 'toggle_adduser_modal':
            
            return {
                ...state,
                isUserOpen : action.payload.isUserOpen,
                roles : action.payload.roles,
                ulns : action.payload.ulns,
                contact: action.payload?.contact || {},
                modalMessage: action.payload.modalMessage,
                refresh: action.payload?.refresh
            }
        case 'toggle_delete_modal':
            return{
                ...state,
                isDeleteModalOpened: action.payload.isDeleteModalOpened,
                modalDeleteMessage: action.payload.modalDeleteMessage,
                deleteContact: action.payload.deleteContact,
                contact: action.payload?.contact || {},
            }
        default:
            throw new Error();
    }
}

const StateProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return <Provider value={{state, dispatch}}>{children}</Provider>;
};

export {store, StateProvider}
